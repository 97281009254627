import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={1}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 1</H1>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>Imagine that you have just created a new product that helps people's health or well-being. With 2-3 other class members, create a list of the types of things that you would tell people about your product so you could sell a lot of it. Keep your list of ideas in your science notebook.</p>
            <p>As you make your list, think about who you would tell about your product, what it does, why they should use it, and other general issues.</p>
            <p>Be ready to share your ideas in a class discussion.</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Students begin this lesson working in teams of 3-4 to brainstorm a list of things they would tell people about a new product they have created. This should be a general list including things such as telling about how good the product is, how it can help most people, and so forth. This should take only a few minutes (5 minutes or less) before students share ideas with the class. The discussion should be fast-paced. It is likely that students' lists will include only the positive aspects of their product (which will set them up for a later step). Do not challenge or try to supplement their lists at this time.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

